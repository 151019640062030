import http from '@/services/http'
import { isEmpty } from 'utils/lodash'

const state = {
  goal: {},
  workOrderGoals: [],
  subsectionComponentOptions: []
}

// getters
const getters = {
  hasGoal: state => !isEmpty(state.goal),
  getGoals: (state) => {
    return state.workOrderGoals
  },
  getSubsectionComponentOptions: (state) => state.subsectionComponentOptions
}

// actions
const actions = {
  async fetchGoals ({ commit }, workOrderId) {
    try {
      const { data } = await http.get(`work-orders/${workOrderId}/goals?per_page=all`)
      commit('SET_WORK_ORDER_GOALS', data.data)
      return data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async getGoalsByPolicyId ({ commit }, { workOrderId, policyId }) {
    try {
      const { data } = await http.get(`work-orders/${workOrderId}/goals?per_page=all&filter[policy_id]=${policyId}`)
      commit('SET_WORK_ORDER_GOALS_BY_POLICY_ID', { policyId, goals: data.data })
      return data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async getGoal ({ commit }, { workOrderId, goalId }) {
    try {
      const { data } = await http.get(`work-orders/${workOrderId}/goals/${goalId}`)
      commit('SET_GOAL', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async storeWorkOrderGoal ({ commit }, { workOrderId, templateGoalId, title, externalReferenceId, subsectionComponentId }) {
    const payload = {
      goal_id: templateGoalId,
      title: title ?? null,
      external_reference_id: externalReferenceId ?? null
    }
    if (subsectionComponentId) {
      payload.subsection_component_id = subsectionComponentId
    }
    try {
      const { data } = await http.post(`work-orders/${workOrderId}/goals`, payload)
      commit('ADD_WORK_ORDER_GOAL', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async orderGoalsInPolicy ({ commit }, { workOrderId, ids, policyId }) {
    try {
      const { data } = await http.post(`work-orders/${workOrderId}/goals/order`, {
        ids
      })
      return data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async syncGoalsInPolicy ({ commit }, { workOrderId, ids, policyId }) {
    try {
      const { data } = await http.post(`work-orders/${workOrderId}/goals/sync`, {
        ids: ids ?? [],
        policy_id: policyId
      })
      return data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async detachGoal ({ commit, state }, { workOrderId, goalId }) {
    try {
      const index = state.workOrderGoals.findIndex((goal) => goalId === goal.id)
      const { data } = await http.delete(`work-orders/${workOrderId}/goals/${goalId}`)
      commit('DELETE_GOAL_AT', index)
      return data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async completeWorkOrderTask ({ commit }, { workOrderId, taskId }) {
    try {
      const { data } = await http.patch(`work-orders/${workOrderId}/work-order-tasks/${taskId}/complete`)
      commit('SET_WORK_ORDER_TASK_STATUS', data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async incompleteWorkOrderTask ({ commit }, { workOrderId, taskId }) {
    try {
      const { data } = await http.patch(`work-orders/${workOrderId}/work-order-tasks/${taskId}/incomplete`)
      commit('SET_WORK_ORDER_TASK_STATUS', data)
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async checkForGoals (_, workOrderId) {
    try {
      return await http.post(`work-orders/${workOrderId}/goals/attach`)
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async fetchSubsectionComponentOptions ({ commit }, policyId) {
    try {
      const data = await http.get(`policies/${policyId}/app-home/subsection-component-options/goals`)
      commit('SET_SUBSECTION_COMPONENT_OPTIONS', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

// mutations
const mutations = {
  SET_GOAL (state, value) {
    state.goal = value
  },

  SET_WORK_ORDER_GOALS (state, value) {
    state.workOrderGoals = value
  },

  ADD_WORK_ORDER_GOAL (state, goal) {
    state.workOrderGoals.push(goal)
  },

  DELETE_GOAL_AT (state, index) {
    state.workOrderGoals.splice(index, 1)
  },

  SET_WORK_ORDER_TASK_STATUS (state, { data }) {
    const goal = state.goal
    for (const sectionIndex in goal.sections) {
      const section = goal.sections[sectionIndex]
      for (const sectionItemIndex in section.items) {
        const sectionItem = section.items[sectionItemIndex]
        if (sectionItem.item?.work_order_task?.id === data.id) {
          sectionItem.item.work_order_task = data
        }
      }
    }
    state.goal = goal
  },

  SET_SUBSECTION_COMPONENT_OPTIONS (state, value) {
    state.subsectionComponentOptions = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
