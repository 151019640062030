import http from '@/services/http'

const SET_REMINDERS = 'SET_REMINDERS'
const ADD_REMINDER = 'ADD_REMINDER'
const UPDATE_REMINDER = 'UPDATE_REMINDER'
const DELETE_REMINDER = 'DELETE_REMINDER'
const SET_REMINDER_ACTION_OPTIONS = 'SET_REMINDER_ACTION_OPTIONS'

const state = {
  reminders: [],
  reminderActionOptions: []
}

// getters
const getters = {
  getReminders: () => state.reminders,
  getReminderById: (state) => (reminderId) => {
    return state.reminders.find((r) => reminderId === r.id)
  },
  getReminderActionOptions: () => state.reminderActionOptions,
  getLabelForAction: (state) => (action) => {
    const theAction = state.reminderActionOptions.find((a) => action === a.value)
    return theAction?.label
  }
}

// actions
const actions = {
  async listReminders ({ commit }, { goalId, taskId }) {
    const { data } = await http.get(`goals/${goalId}/tasks/${taskId}/reminders`)
    commit(SET_REMINDERS, data.data)
  },
  async createReminder ({ commit }, { goalId, taskId, reminder }) {
    const { data } = await http.post(`goals/${goalId}/tasks/${taskId}/reminders`, reminder)
    commit(ADD_REMINDER, data.data)
  },
  async updateReminder ({ commit, state }, { goalId, taskId, reminder }) {
    const { data } = await http.patch(`goals/${goalId}/tasks/${taskId}/reminders/${reminder.id}`, reminder)
    const index = state.reminders.findIndex((r) => r.id === reminder.id)
    commit(UPDATE_REMINDER, { index, reminder: data.data })
  },
  async deleteReminder ({ commit, state }, { goalId, taskId, reminder }) {
    await http.delete(`goals/${goalId}/tasks/${taskId}/reminders/${reminder.id}`)
    const index = state.reminders.findIndex((r) => r.id === reminder.id)
    commit(DELETE_REMINDER, index)
  },
  async listReminderActionOptions ({ commit }) {
    const { data } = await http.get('goals/tasks/reminder-actions')
    commit(SET_REMINDER_ACTION_OPTIONS, data.data)
  }
}

// mutations
const mutations = {
  [SET_REMINDERS] (state, value) {
    state.reminders = value
  },
  [ADD_REMINDER] (state, reminder) {
    state.reminders.push(reminder)
  },
  [UPDATE_REMINDER] (state, { index, reminder }) {
    state.reminders.splice(index, 1, reminder)
  },
  [DELETE_REMINDER] (state, index) {
    state.reminders.splice(index, 1)
  },
  [SET_REMINDER_ACTION_OPTIONS] (state, value) {
    state.reminderActionOptions = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
