const state = {
  fullSidebarNavigation: true,
  showMobileMenu: false,
  showSearchBar: false,
  processingError: false,
  processing: 0
}

// getters
const getters = {
  fullSidebarNavigation: state => state.fullSidebarNavigation,
  showSearchBar: state => state.showSearchBar,
  processingError: state => state.processingError,
  isProcessing: state => state.processing > 0
}

// actions
const actions = {
  toggleSidebar ({ commit }, value) {
    commit('SET_SIDEBAR_NAVIGATION', value)
  },
  toggleSearchBar ({ commit }, value) {
    commit('SET_SEARCH_BAR', value)
  },
  decrementProcessing ({ commit }) {
    commit('DECREMENT_PROCESSING')
  },
  incrementProcessing ({ commit, state }) {
    // reset any processing errors at the beginning of the next round of requests
    if (state.processing === 0) {
      commit('SET_PROCESSING_ERROR', false)
    }
    commit('INCREMENT_PROCESSING')
  },
  processingError ({ commit }) {
    commit('SET_PROCESSING_ERROR', true)
  }
}

// mutations
const mutations = {
  DECREMENT_PROCESSING (state) {
    state.processing--
  },
  HIDE_MOBILE_MENU (state) {
    state.showMobileMenu = false
  },
  INCREMENT_PROCESSING (state) {
    state.processing++
  },
  SET_SIDEBAR_NAVIGATION (state, value) {
    state.fullSidebarNavigation = value
  },
  SET_SEARCH_BAR (state, value) {
    state.showSearchBar = value
  },
  SHOW_MOBILE_MENU (state) {
    state.showMobileMenu = true
  },
  SET_PROCESSING_ERROR (state, value) {
    state.processingError = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
