export default {
  application: {
    read: ['Super Admin', 'Admin', 'Dispatcher', 'Agent', 'Super Agent', 'Area Ops Manager', 'Host']
  },
  404: {
    read: ['*']
  },
  home: {
    read: ['Admin', 'Super Admin', 'Dispatcher', 'Super Agent', 'Agent', 'Area Ops Manager', 'Host'],
    create: ['Super Admin', 'Admin'],
    update: ['Super Admin', 'Admin'],
    delete: ['Super Admin', 'Admin']
  },
  /* Users */
  'users.index': {
    read: ['Admin', 'Super Admin', 'Dispatcher', 'Area Ops Manager'],
    create: ['Admin', 'Super Admin', 'Dispatcher', 'Area Ops Manager'],
    update: ['Admin', 'Super Admin', 'Dispatcher', 'Area Ops Manager'],
    delete: ['Admin', 'Super Admin', 'Dispatcher', 'Area Ops Manager']
  },
  'users.edit': {
    read: ['Admin', 'Super Admin', 'Dispatcher', 'Area Ops Manager', 'Host', 'Super Agent', 'Agent'],
    create: ['Admin', 'Super Admin', 'Dispatcher', 'Area Ops Manager'],
    update: ['Admin', 'Super Admin', 'Dispatcher', 'Area Ops Manager'],
    delete: ['Admin', 'Super Admin', 'Dispatcher', 'Area Ops Manager']
  },
  'users.edit.phone-numbers': {
    create: ['Admin', 'Super Admin', 'Dispatcher', 'Area Ops Manager', 'Host']
  },
  'users.edit.addresses': {
    create: ['Admin', 'Super Admin', 'Dispatcher', 'Area Ops Manager']
  },
  'users.employee.profile': {
    read: ['Admin', 'Super Admin', 'Dispatcher', 'Area Ops Manager'],
    create: ['Admin', 'Super Admin', 'Dispatcher', 'Area Ops Manager'],
    update: ['Admin', 'Super Admin', 'Dispatcher', 'Area Ops Manager'],
    delete: ['Admin', 'Super Admin', 'Dispatcher', 'Area Ops Manager']
  },
  'users.work.orders': {
    read: ['Admin', 'Super Admin', 'Dispatcher', 'Area Ops Manager', 'Host'],
    create: ['Admin', 'Super Admin', 'Dispatcher', 'Area Ops Manager'],
    update: ['Admin', 'Super Admin', 'Dispatcher', 'Area Ops Manager'],
    delete: ['Admin', 'Super Admin', 'Dispatcher', 'Area Ops Manager']
  },
  'users.timesheets': {
    read: ['Admin', 'Super Admin', 'Dispatcher', 'Area Ops Manager', 'Host'],
    create: ['Admin', 'Super Admin', 'Dispatcher', 'Area Ops Manager'],
    update: ['Admin', 'Super Admin', 'Dispatcher', 'Area Ops Manager'],
    delete: ['Admin', 'Super Admin', 'Dispatcher', 'Area Ops Manager'],
    approve: ['Admin', 'Super Admin', 'Area Ops Manager']
  },
  'users.timesheets.view': {
    read: ['Admin', 'Super Admin', 'Dispatcher', 'Area Ops Manager', 'Host'],
    create: ['Admin', 'Super Admin', 'Dispatcher', 'Area Ops Manager'],
    update: ['Admin', 'Super Admin', 'Dispatcher', 'Area Ops Manager'],
    delete: ['Admin', 'Super Admin', 'Dispatcher', 'Area Ops Manager']
  },
  /* Areas */
  'areas.index': {
    read: ['Super Admin', 'Admin'],
    create: ['Super Admin', 'Admin'],
    update: ['Super Admin', 'Admin'],
    delete: ['Super Admin', 'Admin']
  },
  'areas.create': {
    read: ['Super Admin', 'Admin'],
    create: ['Super Admin', 'Admin'],
    update: ['Super Admin', 'Admin'],
    delete: ['Super Admin', 'Admin']
  },
  'areas.edit': {
    read: ['Super Admin', 'Admin'],
    create: ['Super Admin', 'Admin'],
    update: ['Super Admin', 'Admin'],
    delete: ['Super Admin', 'Admin']
  },
  /* Companies */
  'companies.index': {
    read: ['Super Admin', 'Admin'],
    create: ['Super Admin', 'Admin'],
    update: ['Super Admin', 'Admin'],
    delete: ['Super Admin', 'Admin']
  },
  'companies.edit': {
    read: ['Super Admin', 'Admin'],
    create: ['Super Admin', 'Admin'],
    update: ['Super Admin', 'Admin'],
    delete: ['Super Admin', 'Admin']
  },
  'companies.onboard': {
    read: ['Super Admin', 'Admin'],
    create: ['Super Admin', 'Admin'],
    update: ['Super Admin', 'Admin'],
    delete: ['Super Admin', 'Admin']
  },
  /* Billing Terms */
  'billing-terms.index': {
    read: ['Super Admin', 'Admin'],
    create: ['Super Admin', 'Admin'],
    update: ['Super Admin', 'Admin'],
    delete: ['Super Admin', 'Admin']
  },
  'billing-terms.create': {
    read: ['Super Admin', 'Admin'],
    create: ['Super Admin', 'Admin'],
    update: ['Super Admin', 'Admin'],
    delete: ['Super Admin', 'Admin']
  },
  'billing-terms.edit': {
    read: ['Super Admin', 'Admin'],
    create: ['Super Admin', 'Admin'],
    update: ['Super Admin', 'Admin'],
    delete: ['Super Admin', 'Admin']
  },
  /* Services */
  'services.index': {
    read: ['Super Admin', 'Admin'],
    create: ['Super Admin', 'Admin'],
    update: ['Super Admin', 'Admin'],
    delete: ['Super Admin', 'Admin']
  },
  'services.create': {
    read: ['Super Admin', 'Admin'],
    create: ['Super Admin', 'Admin'],
    update: ['Super Admin', 'Admin'],
    delete: ['Super Admin', 'Admin']
  },
  'services.edit': {
    read: ['Super Admin', 'Admin'],
    create: ['Super Admin', 'Admin'],
    update: ['Super Admin', 'Admin'],
    delete: ['Super Admin', 'Admin']
  },
  /* Policies */
  'policies.index': {
    read: ['Super Admin', 'Admin'],
    create: ['Super Admin', 'Admin'],
    update: ['Super Admin', 'Admin'],
    delete: ['Super Admin', 'Admin']
  },
  'policies.edit': {
    read: ['Super Admin', 'Admin'],
    create: ['Super Admin', 'Admin'],
    update: ['Super Admin', 'Admin'],
    delete: ['Super Admin', 'Admin']
  },
  /* Policies */
  'policy-options.index': {
    read: ['Super Admin', 'Admin'],
    create: ['Super Admin', 'Admin'],
    update: ['Super Admin', 'Admin'],
    delete: ['Super Admin', 'Admin']
  },
  'policy-options.edit': {
    read: ['Super Admin', 'Admin'],
    create: ['Super Admin', 'Admin'],
    update: ['Super Admin', 'Admin'],
    delete: ['Super Admin', 'Admin']
  },
  /* Forms */
  'forms.index': {
    read: ['Super Admin', 'Admin'],
    create: ['Super Admin', 'Admin'],
    update: ['Super Admin', 'Admin'],
    delete: ['Super Admin', 'Admin']
  },
  'forms.edit': {
    read: ['Super Admin', 'Admin'],
    create: ['Super Admin', 'Admin'],
    update: ['Super Admin', 'Admin'],
    delete: ['Super Admin', 'Admin']
  },
  /* Goals */
  'goals.index': {
    read: ['Admin', 'Super Admin'],
    create: ['Admin', 'Super Admin'],
    update: ['Admin', 'Super Admin'],
    delete: ['Admin', 'Super Admin']
  },
  'goals.create': {
    read: ['Admin', 'Super Admin'],
    create: ['Admin', 'Super Admin'],
    update: ['Admin', 'Super Admin'],
    delete: ['Admin', 'Super Admin']
  },
  'goals.edit': {
    read: ['Admin', 'Super Admin'],
    create: ['Admin', 'Super Admin'],
    update: ['Admin', 'Super Admin'],
    delete: ['Admin', 'Super Admin']
  },
  /* Topics */
  'topics.index': {
    read: ['Admin', 'Super Admin'],
    create: ['Admin', 'Super Admin'],
    update: ['Admin', 'Super Admin'],
    delete: ['Admin', 'Super Admin']
  },
  'topics.create': {
    read: ['Admin', 'Super Admin'],
    create: ['Admin', 'Super Admin'],
    update: ['Admin', 'Super Admin'],
    delete: ['Admin', 'Super Admin']
  },
  'topics.edit': {
    read: ['Admin', 'Super Admin'],
    create: ['Admin', 'Super Admin'],
    update: ['Admin', 'Super Admin'],
    delete: ['Admin', 'Super Admin']
  },
  /* Announcements */
  'announcements.index': {
    read: ['Admin', 'Super Admin', 'Super Agent'],
    create: ['Admin', 'Super Admin', 'Super Agent'],
    update: ['Admin', 'Super Admin', 'Super Agent'],
    delete: ['Admin', 'Super Admin', 'Super Agent']
  },
  'announcements.create': {
    read: ['Admin', 'Super Admin', 'Super Agent'],
    create: ['Admin', 'Super Admin', 'Super Agent'],
    update: ['Admin', 'Super Admin', 'Super Agent'],
    delete: ['Admin', 'Super Admin', 'Super Agent']
  },
  'announcements.edit': {
    read: ['Admin', 'Super Admin', 'Super Agent'],
    create: ['Admin', 'Super Admin', 'Super Agent'],
    update: ['Admin', 'Super Admin', 'Super Agent'],
    delete: ['Admin', 'Super Admin', 'Super Agent']
  },
  /* Transferees */
  'transferees.index': {
    read: ['Super Agent', 'Agent', 'Super Admin']
  },
  'transferees.view': {
    read: ['Super Agent', 'Agent', 'Super Admin']
  },
  /* Service Categories */
  'service-categories.index': {
    read: ['Super Admin', 'Admin'],
    create: ['Super Admin', 'Admin'],
    update: ['Super Admin', 'Admin'],
    delete: ['Super Admin', 'Admin']
  },
  'service-categories.create': {
    read: ['Super Admin', 'Admin'],
    create: ['Super Admin', 'Admin'],
    update: ['Super Admin', 'Admin'],
    delete: ['Super Admin', 'Admin'],
    readOnlyFields: {
      Admin: [
        'model_label'
      ]
    }
  },
  'service-categories.edit': {
    read: ['Super Admin', 'Admin'],
    create: ['Super Admin', 'Admin'],
    update: ['Super Admin', 'Admin'],
    delete: ['Super Admin', 'Admin'],
    readOnlyFields: {
      Admin: [
        'model_label'
      ]
    }
  },
  /* Products */
  'products.index': {
    read: ['Super Admin'],
    create: ['Super Admin'],
    update: ['Super Admin'],
    delete: ['Super Admin']
  },
  'products.create': {
    read: ['Super Admin'],
    create: ['Super Admin'],
    update: ['Super Admin'],
    delete: ['Super Admin']
  },
  'products.edit': {
    read: ['Super Admin'],
    create: ['Super Admin'],
    update: ['Super Admin'],
    delete: ['Super Admin']
  },
  /* Work Orders */
  'work-orders.index': {
    read: ['Admin', 'Super Admin', 'Dispatcher', 'Host', 'Area Ops Manager'],
    create: ['Admin', 'Super Admin', 'Dispatcher'],
    update: ['Admin', 'Super Admin', 'Dispatcher'],
    delete: ['Admin', 'Super Admin', 'Dispatcher']
  },
  'work-orders.create': {
    read: ['Admin', 'Super Admin', 'Super Agent', 'Agent'],
    create: ['Admin', 'Super Admin', 'Super Agent', 'Agent'],
    update: ['Admin', 'Super Admin'],
    delete: ['Admin', 'Super Admin']
  },
  'work-orders.activity': {
    read: ['Admin', 'Super Admin', 'Dispatcher', 'Area Ops Manager', 'Host'],
    create: ['Admin', 'Super Admin'],
    update: ['Admin', 'Super Admin'],
    delete: ['Admin', 'Super Admin']
  },
  'work-orders.validations': {
    read: ['Admin', 'Super Admin', 'Dispatcher', 'Area Ops Manager', 'Host'],
    create: ['Admin', 'Super Admin'],
    update: ['Admin', 'Super Admin'],
    delete: ['Admin', 'Super Admin']
  },
  'work-orders.events': {
    read: ['Admin', 'Super Admin', 'Dispatcher', 'Area Ops Manager', 'Host'],
    create: ['Admin', 'Super Admin'],
    update: ['Admin', 'Super Admin'],
    delete: ['Admin', 'Super Admin']
  },
  'work-orders.details': {
    read: ['Admin', 'Super Admin', 'Dispatcher', 'Area Ops Manager', 'Host'],
    create: ['Admin', 'Super Admin'],
    update: ['Admin', 'Super Admin', 'Area Ops Manager'],
    delete: ['Admin', 'Super Admin']
  },
  'work-orders.add-ons': {
    read: ['Super Admin']
  },
  'work-orders.profile': {
    read: ['Admin', 'Super Admin', 'Dispatcher', 'Area Ops Manager', 'Host'],
    create: ['Admin', 'Super Admin'],
    update: ['Admin', 'Super Admin'],
    delete: ['Admin', 'Super Admin']
  },
  'work-orders.favorites': {
    read: ['Admin', 'Super Admin', 'Dispatcher', 'Area Ops Manager', 'Host'],
    create: ['Admin', 'Super Admin'],
    update: ['Admin', 'Super Admin'],
    delete: ['Admin', 'Super Admin']
  },
  'work-orders.notes': {
    read: ['Admin', 'Super Admin', 'Dispatcher', 'Area Ops Manager', 'Host'],
    create: ['Admin', 'Super Admin'],
    update: ['Admin', 'Super Admin'],
    delete: ['Admin', 'Super Admin']
  },
  'work-orders.documents': {
    read: ['Admin', 'Super Admin', 'Dispatcher', 'Area Ops Manager', 'Host'],
    create: ['Admin', 'Super Admin'],
    update: ['Admin', 'Super Admin'],
    delete: ['Admin', 'Super Admin']
  },
  'work-orders.allocated-hours': {
    read: ['Admin', 'Super Admin', 'Dispatcher', 'Area Ops Manager'],
    create: ['Admin', 'Super Admin'],
    update: ['Admin', 'Super Admin'],
    delete: ['Admin', 'Super Admin']
  },
  'work-orders.bookings': {
    read: ['Admin', 'Super Admin', 'Area Ops Manager', 'Host'],
    create: ['Admin', 'Super Admin'],
    update: ['Admin', 'Super Admin'],
    delete: ['Admin', 'Super Admin']
  },
  'work-orders.bookings.create': {
    read: ['Admin', 'Super Admin', 'Area Ops Manager', 'Host'],
    create: ['Admin', 'Super Admin'],
    update: ['Admin', 'Super Admin'],
    delete: ['Admin', 'Super Admin']
  },
  'work-orders.bookings.edit.overview': {
    read: ['Admin', 'Super Admin', 'Area Ops Manager', 'Host'],
    create: ['Admin', 'Super Admin'],
    update: ['Admin', 'Super Admin'],
    delete: ['Admin', 'Super Admin']
  },
  'work-orders.bookings.edit.activity': {
    read: ['Admin', 'Super Admin', 'Area Ops Manager', 'Host'],
    create: ['Admin', 'Super Admin'],
    update: ['Admin', 'Super Admin'],
    delete: ['Admin', 'Super Admin']
  },
  'work-orders.requested-bookings.finalize': {
    read: ['Admin', 'Super Admin', 'Area Ops Manager', 'Host'],
    create: ['Admin', 'Super Admin'],
    update: ['Admin', 'Super Admin'],
    delete: ['Admin', 'Super Admin']
  },
  'work-orders.co-hosts': {
    read: ['Admin', 'Super Admin', 'Area Ops Manager'],
    create: ['Admin', 'Super Admin'],
    update: ['Admin', 'Super Admin'],
    delete: ['Admin', 'Super Admin']
  },
  'work-orders.conversations': {
    read: ['Admin', 'Super Admin', 'Area Ops Manager', 'Host'],
    create: ['Admin', 'Super Admin'],
    update: ['Admin', 'Super Admin'],
    delete: ['Admin', 'Super Admin']
  },
  'work-orders.intercom-conversations': {
    read: ['Admin', 'Super Admin', 'Area Ops Manager', 'Host'],
    create: ['Admin', 'Super Admin'],
    update: ['Admin', 'Super Admin'],
    delete: ['Admin', 'Super Admin']
  },
  'work-orders.intercom-conversations.show': {
    read: ['Admin', 'Super Admin', 'Area Ops Manager', 'Host'],
    create: ['Admin', 'Super Admin'],
    update: ['Admin', 'Super Admin'],
    delete: ['Admin', 'Super Admin']
  },
  'work-orders.drive': {
    read: ['Admin', 'Super Admin', 'Dispatcher', 'Area Ops Manager', 'Host'],
    create: ['Admin', 'Super Admin'],
    update: ['Admin', 'Super Admin'],
    delete: ['Admin', 'Super Admin']
  },
  'work-orders.map': {
    read: ['Admin', 'Super Admin', 'Dispatcher', 'Area Ops Manager', 'Host'],
    create: ['Admin', 'Super Admin'],
    update: ['Admin', 'Super Admin'],
    delete: ['Admin', 'Super Admin']
  },
  'work-orders.forms': {
    read: ['Admin', 'Super Admin', 'Area Ops Manager', 'Host'],
    create: ['Admin', 'Super Admin'],
    update: ['Admin', 'Super Admin'],
    delete: ['Admin', 'Super Admin']
  },
  // todo needs to be set correctly
  'work-orders.form': {
    read: ['Admin', 'Super Admin', 'Area Ops Manager', 'Host'],
    create: ['Admin', 'Super Admin'],
    update: ['Admin', 'Super Admin'],
    delete: ['Admin', 'Super Admin']
  },
  'work-orders.goals': {
    read: ['Admin', 'Super Admin', 'Area Ops Manager', 'Host']
  },
  'work-orders.delegates': {
    read: ['Admin', 'Super Admin']
  },
  'work-orders.goal': {
    read: ['Admin', 'Super Admin', 'Area Ops Manager', 'Host']
  },
  /* Linkables */
  'linkables.index': {
    read: ['Admin', 'Super Admin'],
    create: ['Admin', 'Super Admin'],
    update: ['Admin', 'Super Admin'],
    delete: ['Admin', 'Super Admin']
  },
  'linkables.create': {
    read: ['Admin', 'Super Admin'],
    create: ['Admin', 'Super Admin'],
    update: ['Admin', 'Super Admin'],
    delete: ['Admin', 'Super Admin']
  },
  'linkables.edit': {
    read: ['Super Admin', 'Admin'],
    create: ['Super Admin', 'Admin'],
    update: ['Super Admin', 'Admin'],
    delete: ['Super Admin', 'Admin']
  },
  /* Links */
  'links.index': {
    read: ['Admin', 'Super Admin'],
    create: ['Admin', 'Super Admin'],
    update: ['Admin', 'Super Admin'],
    delete: ['Admin', 'Super Admin']
  },
  'links.create': {
    read: ['Admin', 'Super Admin'],
    create: ['Admin', 'Super Admin'],
    update: ['Admin', 'Super Admin'],
    delete: ['Admin', 'Super Admin']
  },
  'links.edit': {
    read: ['Super Admin', 'Admin'],
    create: ['Super Admin', 'Admin'],
    update: ['Super Admin', 'Admin'],
    delete: ['Super Admin', 'Admin']
  },
  'notifications.callback': {
    read: ['Super Admin', 'Admin', 'Area Ops Manager', 'Host'],
    create: ['Super Admin', 'Admin', 'Area Ops Manager', 'Host'],
    update: ['Super Admin', 'Admin', 'Area Ops Manager', 'Host'],
    delete: ['Super Admin', 'Admin', 'Area Ops Manager', 'Host']
  }
}
