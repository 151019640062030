import http from '@/services/http'

// actions
const actions = {
  async retryValidation ({ commit }, { workOrderId, validationCode }) {
    try {
      await http.post(`work-orders/${workOrderId}/validations/${validationCode}/retry`)
      return true
    } catch (e) {
      return Promise.reject(e)
    }
  }
}
// mutations
const mutations = {}
export default {
  namespaced: true,
  actions,
  mutations
}
