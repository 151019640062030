import http from '@/services/http'

const state = {
  codes: []
}

// getters
const getters = {
  codes: ({ codes }) => codes
}

// actions
const actions = {
  async all ({ commit }) {
    const { data } = await http.get('codes')
    commit('SET_CODES', data.data)
  }
}

// mutations
const mutations = {
  SET_CODES (state, codes) {
    state.codes = codes
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
