import http from '@/services/http'
import { isEmpty } from 'utils/lodash'

const state = {
  form: {}
}

// getters
const getters = {
  hasForm: state => !isEmpty(state.form)
}

// actions
const actions = {
  async getForms ({ commit }, { workOrderId, page, perPage }) {
    try {
      const { data } = await http.get(`work-orders/${workOrderId}/forms?sort=&page=${page}&per_page=${perPage}`)
      return data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async getAddonForms ({ commit }, { workOrderId, page, perPage }) {
    try {
      const { data } = await http.get(`work-orders/${workOrderId}/addon-forms?sort=&page=${page}&per_page=${perPage}`)
      return data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async getForm ({ commit }, { workOrderId, formId }) {
    try {
      const { data } = await http.get(`work-orders/${workOrderId}/forms/${formId}`)
      commit('SET_FORM', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async submitAnswers ({ commit }, { workOrderId, formId, payload }) {
    try {
      const { data } = await http.post(`work-orders/${workOrderId}/forms/${formId}`, payload)
      commit('SET_FORM', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

// mutations
const mutations = {
  SET_FORM (state, value) {
    state.form = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
